import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css, Global } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { useEffect } from 'react'
import { useLayoutEffect } from 'react'
import { useState } from 'react'
import Image from '~components/Image'

const MouseImage = ({ images, index }) => {

	const [mouseX, setMouseX] = useState(false)
	const [mouseY, setMouseY] = useState(false)
	const [show, setShow] = useState(false)

	useEffect(() => {
		if (index !== undefined && images[index]?.hoverImage){
			setShow(true)
		} else {
			setShow(false)
		}
	}, [index])

	useLayoutEffect(() => {
		window.addEventListener('mousemove', e => {
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
		return () => window.removeEventListener('mousemove', e => {
			setMouseX(e.clientX)
			setMouseY(e.clientY)
		})
	}, [])

	return (
		<>
			{show && 
				<Global styles={css`
					html * {
						cursor: none;
					}
				`} />
			}
			<CursorImage 
				image={images[index]?.hoverImage}
				css={css`
						left: ${mouseX}px;
						top: ${mouseY}px;
						opacity: ${show ? '1' : '0'};
				`}
			/>
		</>
	)
}

const CursorImage = styled(Image)`
	position: fixed;
	width: 400px;
	pointer-events: none;
  transform: translate( 0px, -5px );
	z-index: 10;
`

MouseImage.propTypes = {
	images: PropTypes.array,
	index: PropTypes.number,
}

export default MouseImage