import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { Collapse } from 'react-collapse'

const AccordionRow = ({ className, title, children, mobile }) => {
	const [open, setOpen] = useState(false)
	return (
		<Wrap className={className} mobile={mobile}>
			<Header onClick={() => setOpen(!open)} mobile={mobile}>
				{mobile ? <h4>{title}</h4> : <span>{title}</span>}
				<span>{open ? '–' : '+'}</span>
			</Header>
			<Collapse isOpened={open}>
				<div>
					<Children mobile={mobile}>
						{children}
					</Children>
				</div>
			</Collapse>
		</Wrap>
	)
}

const Wrap = styled.div`
	border-bottom: ${props => props.mobile ? '1px solid #000' : 0};
`

const Children = styled.div`
	padding: ${props => props.mobile ? 0 : '1em 0 2em 1em'};
	/* span {
		display: block;
		border-top: 1px solid #000;
		margin: 0.05em 0;
    padding-top: 0.05em;
	} */
`

const Header = styled.button`
	display: flex;
	text-transform: uppercase;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border-top: ${props => props.mobile ? 'none' : '1px solid #000'};
	padding: ${props => props.mobile ? '0.04em 0 0.06em' : '0.18em 0 0.04em'};
	span:nth-child(2){
		display: block;
		transform: translateY(-0.02em);
	}
	button {
		text-transform: inherit;
	}
	h3{
		padding: 0.05em 0;
	}
`

AccordionRow.propTypes = {
	className: PropTypes.string
}

export default AccordionRow